import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import Cookies from "js-cookie";

// Data Modules
import { fdict } from "@/store/data/fileDictionaries";
import { shipControl } from "@/store/data/controls/ship"
import { logControl } from "@/store/data/controls/log"
import { mrkControl } from "@/store/data/controls/mrk";
import { priceControl } from "@/store/data/controls/price";
import { printControl } from "@/store/data/controls/print";
import { fieldServiceControl } from "./data/controls/fieldService";
import { coControl } from "./data/controls/co";
import { laborControl } from "./data/controls/labor";
import { users } from "@/store/data/users";
import { terms } from "@/store/data/terms";
import { stax } from "@/store/data/stax";
import { rep } from "@/store/data/rep";
import { woControl } from "@/store/data/controls/workOrder";

// UI Modules
import { notification } from "@/store/ui/notification";
import { invoice } from "@/store/ui/invoice";
import { customer } from "@/store/ui/customer";
import { session } from "@/store/ui/session";
import { sidemenu } from "@/store/ui/sidemenu";
import { payment } from "@/store/ui/payment";
import { theme } from "@/store/ui/theme";
import { customerInquiry } from "@/store/ui/customerInquiry";
import { RootState } from "@/types/state";
import { workorder } from "@/store/ui/workorder";
import { inventory } from "@/store/ui/inventory";
import { billing } from "@/store/ui/billing";
import { dashboard } from "@/store/ui/dashboard";
import { sales } from "@/store/ui/sales";
import { inventoryInquiry } from "@/store/ui/inventoryInquiry";
import { parts } from "@/store/ui/parts";
import { saleOpps } from "@/store/ui/saleOpps";
import { control } from "@/store/ui/control";
import { filters } from "@/store/data/filters";
import { soQuotes } from "@/store/ui/soQuotes";
import { cash } from "@/store/ui/cash";
import { accountsPayable } from "@/store/ui/accountsPayable";
import { accountsReceivable } from "@/store/ui/accountsReceivable";
import { attachments } from "@/store/ui/attachments";
import { salesInquiry } from "@/store/ui/salesInquiry";
import { title } from "@/store/ui/title";
import { partsCheckout } from "@/store/ui/partsCheckout";
import { pos } from "@/store/ui/pos";
import { printableDatatable } from "@/store/ui/printableDatatable";
import { scan } from "@/store/ui/scan";
import { fieldServices } from "@/store/ui/fieldServices";
import { env } from "@/store/ui/env";
import { accountingPayables } from "./ui/accountingPayables";
import { accountingReceivables } from "./ui/accountingReceivables";
import { accountingForm } from "./ui/accountingForm";
import { accountingInquiry } from "./ui/accountingInquiry";
import { generalLedger } from "./ui/generalLedger";

export default createStore<RootState>({
  modules: {
    fdict,
    shipControl,
    notification,
    invoice,
    customer,
    session,
    sidemenu,
    payment,
    theme,
    customerInquiry,
    workorder,
    inventory,
    billing,
    dashboard,
    sales,
    inventoryInquiry,
    salesInquiry,
    parts,
    saleOpps,
    control,
    filters,
    soQuotes,
    accountsPayable,
    accountsReceivable,
    cash,
    attachments,
    title,
    pos,
    partsCheckout,
    printableDatatable,
    scan,
    fieldServices,
    logControl,
    mrkControl,
    priceControl,
    printControl,
    env,
    fieldServiceControl,
    accountingPayables,
    accountingReceivables,
    accountingForm,
    accountingInquiry,
    generalLedger,
    users,
    terms,
    stax,
    laborControl,
    rep,
    coControl,
    woControl,
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
    createPersistedState({
      key: "loginUrl",
      paths: ["session.loginUrl"],
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) => Cookies.set(key, value, { expires: 365, secure: true }),
        removeItem: (key) => Cookies.remove(key),
      },
    }),
    createPersistedState({
      key: "scan",
      paths: ["scan"],
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) => Cookies.set(key, value, { expires: 365, secure: true }),
        removeItem: (key) => Cookies.remove(key),
      },
    }),
    createPersistedState({
      key: "posSelectedFilterOptions",
      paths: ["pos.selectedFilterOptions"],
    }),
    createPersistedState({
      key: "recentlyViewedCustomers",
      paths: ["customerInquiry.recentlyViewed"]
    }),
    createPersistedState({
      key: "posUnsavedOrders",
      paths: ["pos.unsavedOrders"]
    }),
    createPersistedState({
      key: "posSalesOrder",
      paths: ["pos.salesOrder"]
    }),
    createPersistedState({
      key: "POSSettings",
      paths: ["pos.autoLoadDefaultCustomers", "pos.autoAddParts", "pos.registerPrinter", "pos.defaultPickTicketPrinter", "pos.hidePosCustomLineItems", "pos.loadOpenOrdersInParts"],
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) => Cookies.set(key, value, { expires: 365, secure: true }),
        removeItem: (key) => Cookies.remove(key),
      },
    }),
    createPersistedState({
      key: "posCacheStats",
      paths: ["pos.cacheStats"],
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) => Cookies.set(key, value, { expires: 365, secure: true }),
        removeItem: (key) => Cookies.remove(key),
      },
    }),
    createPersistedState({
      key: "controlSettings",
      paths: ["control.waiveConvenienceFeeByDefault"],
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) => Cookies.set(key, value, { expires: 365, secure: true }),
        removeItem: (key) => Cookies.remove(key),
      },
    }),
    createPersistedState({
      key: "sideMenuSettings",
      paths: ["sidemenu.defaultLandingPage"],
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) => Cookies.set(key, value, { expires: 365, secure: true }),
        removeItem: (key) => Cookies.remove(key),
      },
    }),        
  ],
});
