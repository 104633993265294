import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import ControlService from "@/services/ControlService";
import { WorkOrderControlState } from "@/types/controls/workOrder";

const namespaced = true;
const controlService = new ControlService();

export const state: WorkOrderControlState = {
    prevent_excess_completions: "",
    end_Job_Stock_Transfer_Mode: "",
};

export const getters: GetterTree<WorkOrderControlState, RootState> = {
    getPreventExcessCompletions(state) {
        return state.prevent_excess_completions == "Y";
    },
    getEndJobStockTransferMode: (state) => {
      return state.end_Job_Stock_Transfer_Mode;
    },
};

export const mutations: MutationTree<WorkOrderControlState> = {
  SET_PREVENT_EXCESS_COMPLETIONS(state, value) {
      state.prevent_excess_completions = value;
  },
  SET_END_JOB_STOCK_TRANSFER_MODE(state, end_Job_Stock_Transfer_Mode) {
    state.end_Job_Stock_Transfer_Mode = end_Job_Stock_Transfer_Mode;
  },
};

export const actions: ActionTree<WorkOrderControlState, RootState> = {
  async fetchWorkOrderControl({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.end_Job_Stock_Transfer_Mode) {
        resolve(state);
      } else {
        controlService.getControl("WO", "WO.CONTROL", "CONTROL", "")
          .then((response: any) => {
            if(response.length > 0){
              commit("SET_PREVENT_EXCESS_COMPLETIONS", response[0].prevent_excess_completions || "");
              commit("SET_END_JOB_STOCK_TRANSFER_MODE", response[0].end_job_stock_transfer_mode || "");
              resolve(response[0]);
            } else {
              reject("No data returned");
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  },
};

export const woControl: Module<WorkOrderControlState, RootState> = {
  namespaced,
  getters,
  state,
  mutations,
  actions,
};